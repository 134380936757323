import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {oidcConfig} from './services/AuthService';
import reportWebVitals from './reportWebVitals';
//import { UserManager } from 'oidc-client-ts';
import { AuthProvider} from "react-oidc-context";
import App from './App';
/*
export const oidcConfig = {
    authority: "http://localhost:44382/pauth",
    client_id: "oidc-ts-app",
    redirect_uri: "http://localhost:3000/oauth/callback",
    silent_redirect_uri: 'http://localhost:3000/oauth/callback',
    post_logout_redirect_uri: 'http://localhost:3000/',
    response_type: 'code',
    scope: 'profile email roles'
};
*/
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App /> 
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//import { sendOAuthRequest } from '../services/AuthService';
import { AuthContextProps } from "react-oidc-context";
import { Navigate } from "react-router-dom";

function UnAuthenticated(props:{auth:AuthContextProps}) {
   
  if (props.auth.isAuthenticated) {
    return <Navigate to='/resources' replace />;
  }

  return (
    <div className='container text-center m-auto'>
      <span>You are not authenticated - Log In first</span>
      <br />
      <button type="button" className='btn btn-primary' onClick={() => props.auth.signinRedirect()}>Log in</button>
    </div>
  )
}

export default UnAuthenticated;
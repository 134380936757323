import logo from './logo.svg';
import './App.css';

import { useAuth } from "react-oidc-context";
import { useEffect, useState } from 'react';
import { logout } from './services/AuthService'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UnAuthenticated from './pages/unauthenticated.page';
import ProtectedRoute from './components/ProtectedRoute';
import { getResources as getAndreykaResources } from './services/api';
import OAuthCallback from './pages/oauth-callback.page';


function App() {
  const auth = useAuth();


  const AuthTestComponent = ()=>{

    if (auth.isLoading) {
      return <div>Loading...</div>;
  }

  if (auth.error) {
      return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
      return (
          <div>
              Hello {auth.user?.profile.sub}{" "}
              <button type='button' className='btn btn-warning' onClick={()=>logout(auth)}>
                  Log out
              </button>
          </div>
      );
  }

  return <button className='btn btn-primary' onClick={() => auth.signinRedirect()}>Log in</button>;
  }

  //=====================

  //const [isAuthenticated, setIsAuthenticated] = useState(false);
 //const [user, setUser] = useState<any>(null);
 const [resource, setResource] = useState<any>(null)
 const [isLoading, setIsLoading] = useState(true);



useEffect(() => {
   async function fetchData() {
   if(auth.user){
    const data = await getAndreykaResources(auth.user?.access_token);
    setResource(data);
   }

  setIsLoading(false);
}

 auth.user  && fetchData();

}, [auth.user]);

//if (isLoading) {
//  return (<div>Loading...</div>)
//}
/*
  return (
    <div className="container App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"  />


        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <div className='container text-center mt-5'>
      <AuthTestComponent/>
      </div>
    </div>
  );
  */

  return(
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<UnAuthenticated auth={auth} />} />
 
        <Route path={'/resources'} element={
          <ProtectedRoute authenticated={auth.isAuthenticated} redirectPath='/'>
            <span>Authenticated OAuth Server result <br/>: {JSON.stringify(auth.user)}</span>
            <br />
            <span>Resource got with access token: {resource}</span>
 
            <button type='button' className='btn btn-warning' onClick={()=>logout(auth)}>Log out</button>

          </ProtectedRoute>
        } />
 
        <Route path='oauth/signin-callback' element={<OAuthCallback auth={auth}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;



export async function getResources(token:string) {
   let dev = false;
    const url = dev ? 'http://localhost:8000/api/roles/client/test' : 'https://api.tuban.me/api/roles/client/test';


    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
  
    try {
      //console.log('token : \n',token)
      const response = await fetch(url, options);
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data = await response.text();

      console.log('data return from api server :',data)
      return data;
    } catch (error) {
      console.error('There was an error fetching the data', error);
    }
  }
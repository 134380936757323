import { UserManager } from 'oidc-client-ts';
import { AuthContextProps } from "react-oidc-context";
var dev = false;
var redirectUrl = dev  ? 'http://localhost:3000' : 'https://test.tuban.me'
export const oidcConfig = {
    authority: 'https://auth.tuban.me/',
    client_id: 'cef996bb1c7047cdbf74f2dc17d85622',
    redirect_uri: redirectUrl + "/oauth/signin-callback",
    silent_redirect_uri: redirectUrl + '/oauth/signin-callback',
    post_logout_redirect_uri: redirectUrl,
    response_type: 'code',
    scopes: ['openid']
};


const userManager = new UserManager(oidcConfig);


export async function handleOAuthCallback(callbackUrl:any, auth:AuthContextProps) {
    try {
       await auth.signinRedirect(callbackUrl);
        return auth.user;
    } catch(e) {
        alert(e);
        console.log(`error while handling oauth callback: ${e}`);
    }
}

//export async function sendOAuthRequest() {
//    return await userManager.signinRedirect();
//}

// renews token using refresh token
export async function renewToken() {
    const user = await userManager.signinSilent();

    return user;
}


export async function logout(auth:AuthContextProps) {
    await auth.removeUser();
    await auth.clearStaleState();
    await auth.signoutRedirect();
}

// This function is used to access token claims
// `.profile` is available in Open Id Connect implementations
// in simple OAuth2 it is empty, because UserInfo endpoint does not exist
// export async function getRole() {
//     const user = await getUser();
//     return user?.profile?.role;
// }

// This function is used to change account similar way it is done in Google
// export async function selectOrganization() {
//     const args = {
//         prompt: "select_account"
//     }
//     await userManager.signinRedirect(args);
// }
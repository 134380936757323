import { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { AuthContextProps} from "react-oidc-context";

function OAuthCallback(props:{auth:AuthContextProps, redirectTo?:string}) {

    const navigate = useNavigate();

  useEffect(()=>{props.auth.user && navigate('/resources')},[navigate, props.auth.user])
    return <></>
}

export default OAuthCallback;